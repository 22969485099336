import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = [];

export const fetchNews = createAsyncThunk("news/fetch", async () => {
  return await axios.get("/database/news.json?v="+ Math.random());
});

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action) => {
        return action.payload
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
  },
});

export const { setNews } = newsSlice.actions;

export default newsSlice.reducer;
